<!--  -->
<template>
  <div>
        <div  class="tabs-content">
         <!--  a-tabs 示例 --> 
          <a-tabs v-model:activeKey="activeKey"
          hideAdd type="editable-card"
            @change="changePage"
            @edit="removeTab"
          >
              <a-tab-pane
              v-for ="item in visitedRoutes"
              :key="item.fullPath"
              :tab="item.name"
              ></a-tab-pane>
          </a-tabs>
        </div>
        <div style="padding:0 15px">
           <!-- <keep-alive>
             <router-view  :key="$route.fullPath"></router-view>
           </keep-alive> -->

           <router-view  :key="$route.fullPath"></router-view>
            
        </div>
  </div>
</template>

<script>
import {getRoutes} from "vue-router";
// import {watch,reactive,toRefs} from 'vue'
import {message} from 'ant-design-vue'
 

export default {
  data() {
    return {
      activeKey:'',
      visitedRoutes:[],
    };
  },
  created() {
    this.visitedRoutes.push(this.$route);
    this.activeKey = this.$route.fullPath
    // const state = reactive({
    //   activeKey:route.fullPath,
    //   visitedRoutes:[]
    // })
    // this.activeKey = route.fullPath;
    // alert(this.activeKey);
    // this.state = state;
  },
  watch: {
        $route(to, from){
          console.log("change change")
            if(!this.visitedRoutes.find((item) => item.fullPath == to.fullPath)){
              this.visitedRoutes.push(to);
            }
             
             this.activeKey = to.fullPath;
        },
    },
  
  // watch(() => route.fullPath,(to) =>{
  //       if(!state.visitedRoutes.find((item) => item.fullPath == to)) {
  //         const {fullPath, name , path} = route
  //         const routes = {fullPath, name , path}
  //         state.visitedRoutes.push(routes)
  //         state.activeKey = to
  //       } else {
  //         state.activeKey = to
  //       }
  // }),
  methods: {
    changePage(key) {
      this.$router.push(key);
    },

    removeTab(fullPath) {
       if(this.visitedRoutes.length === 1) {
        return message.warning('这已经是最后一页,不能再删除了!')
      }
        // const routePath = this.visitedRoutes.find((item) =>{
        //    return fullPath === item.fullPath
        // })
        var thisindex = 0;
        
        this.visitedRoutes.forEach((item,index)=>{
          if(item.fullPath === fullPath) {
            this.visitedRoutes.splice(index,1)
            thisindex = index
          }
        })

        if(fullPath == this.activeKey) {
          if(thisindex < this.visitedRoutes.length) {
            this.$router.push(this.visitedRoutes[thisindex].fullPath);
          }else{
            this.$router.push(this.visitedRoutes[thisindex-1].fullPath);
          }
        }
        
        
    }

  }
}

</script>
<style>
.ant-tabs-bar{
  margin:0
}
</style>
<style lang='less' scoped>
.tabs-content {
  padding:10px 5px 0 5px;
  margin-bottom:15px;
  background: #f1f1f1;
    // border-top: 1px solid #eee;
    ::v-deep(.tabs) {

    .ant-tabs-bar {
      padding: 4px 20px 0 10px;
      margin: 0;
      background-color: white;
      user-select: none;
    }

    .ant-tabs-tabpane {
      display: none;
    }
  }
  .ant-tabs-tab-active {
  color: #000;
}

  .tabs-view-content {
    padding: 20px 14px 0;
    /*height: calc(100vh - #{$header-height});*/
    height: calc(100vh - 110px);
    overflow: auto;
  }
}
</style>