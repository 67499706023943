<template>
<div>
  <a-layout id="components-layout-demo-custom-trigger" style="min-height: 100vh;">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <div>
          <img style="width:36px;margin-left:22px" src="../assets/images/logo.jpg">
        </div>
        <div style="margin-left:10px" v-if="!collapsed">
          聚灿网管理系统
        </div>
      </div>
      <a-menu
          :default-selected-keys="[$route.path]"
          :default-open-keys="[$route.path]"
          mode="inline"
          theme="dark"
        >
          <template v-for="item in menu">
            <a-menu-item v-if="!item.children && !item.hidden" :key="item.path" @click="menuClick(item, 'none')">
              <a-icon :type="item.icon" />
              <span>{{ item.name }}</span>
            </a-menu-item>
            <a-sub-menu v-if="item.children  && !item.hidden" :key="item.path">
              <span slot="title">
                <a-icon :type="item.icon" />
                <span>{{ item.name }}</span>
              </span>
              <a-menu-item
                v-for="(itemS, indexS) in item.children"
                @click="menuClick(item, indexS)"
                :key="itemS.path"
              >{{ itemS.name }}</a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
    </a-layout-sider>
    
    <a-layout>
      <a-layout-header  style="background: #fff; padding: 0">
        <div style="display:flex">
          <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <div class="f1">
            <div @click="closeChat" style="color:#666;color:#FF4D4F;display:flex;align-items:center;cursor: pointer;">
                <a-icon type="message" :style="{ fontSize: '30px', color: '#FF4D4F' }" style="margin-right:-7px;"/>
                <div>
                  <span style="border:2px solid #FF4D4F;border-radius:0 25px 25px 0;padding:2px 10px;border-left:none;font-size:12px">{{messageText}}</span>
                </div>
                
              </div>
          </div>
          <div style="float:right;margin-right:30px">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <div class="fa">
                  <div class="header_phone">欢迎您，{{ user.s_name }}</div>
                  <a-icon class="header_icon" type="caret-down" />
                </div>
              </a>
              <a-menu slot="overlay" @click="onClick">
                <a-menu-item key="1">修改密码</a-menu-item>
                <a-menu-item key="2">退出登录</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          

        </div>
      </a-layout-header>

      <a-layout-content
        :style="{ padding: '0px', background: '#fff' }"
      >
        <tabs-content />
      </a-layout-content>

    </a-layout>
  </a-layout>
<audio v-show="false" src="../assets/notice.mp3" ref="audio" controls="controls"></audio>
    <audio v-show="false" src="../assets/new_message.mp3" ref="new_message" controls="controls"></audio>
<a-modal
        v-model="alert"
        title="修改密码"
        cancelText="取消"
        okText="确定"
        width="500px"
        :confirm-loading="subLoading"
        @ok="handleOk"
      >
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="请输入原密码">
            <a-input v-model="form.old_password" />
          </a-form-model-item>
          <a-form-model-item label="请输入新密码">
            <a-input v-model="form.password" />
          </a-form-model-item>
          <a-form-model-item label="确认新密码">
            <a-input v-model="form.password_confirm" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

   <div
      v-show="chatHide"
      style="position: fixed;right: 40px;bottom: 40px;box-shadow: 0px 0px 50px #aaa;z-index: 1000;"
    >
      <div style="text-align: right;position: absolute;right: 10px;top: 10px;z-index: 10000;">
        <a-icon type="close" @click="closeChat" :style="{ fontSize: '18px', color: '#666' }" />
      </div>
      <div>
        <lemon-imui
          ref="IMUI"
          :user="userInfo"
          width="1000px"
          height="600px"
          theme="blue"
          hideMenu
          @pull-messages="handlePullMessages"
          @send="handleSend"
          @change-contact="changeContact"
          @message-click="messageClick"
        />
      </div>
   </div>

   
      <a-modal
      title=""
      :visible="imageModel"
     :footer="null"
       width="50%"
      :bodyStyle="{'padding':0}"
      @cancel="imageColse"
    >
      <img style="width:100%" :src="thisImage" alt="">
    </a-modal>
  </div>
</template>
// <script>
// export default {
//   data() {
//     return {
//       collapsed: false,
//     };
//   },
// };
// </script>
<script>
import {
  updatePassword,
  getStockList,
  message_peopleList,
  message_list,
  message_add,
  uploads,
  getLastMessage,
  updateRead,
  storeInfo
} from "@/assets/api/api";

import TabsContent from "./tabs-content.vue";
import qs from "qs";
import { Empty, message } from 'ant-design-vue';

export default {
  components: {
    TabsContent,
  },
  name: "Socket",
  data() {
    return {
      collapsed: false,
      websock: null,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      form: {
        old_password: "",
        password: "",
        password_confirm: ""
      },
      menu: [],
      path: "",
      date: "",
      subLoading: false,
      alert: false,
      stockList: "",
      visible: false,
      userInfo:[],
      userList:[],
      chatHide: false,
      user:[],
      messageText:'立即沟通',
      imageModel:false,
      thisImage:'',
    };
  },
  watch:{
      '$store.state.chatHide'(){
        this.chatHide = this.$store.state.chatHide;
        if(this.chatHide) {
          this.messageText = '立即沟通';
        }
        this.appendContact();
      }
  },
  methods: {
    storeInfo()  {
      // storeInfo().then(res => {
      //   let data = res.data.data;
      //   this.userInfo = {
      //     id:data.uid,
      //     displayName:data.s_name,
      //     avatar:data.s_logo,
      //   }
      //   this.initWebSocket();
      // });

      this.userInfo = {
          id:this.user.uid,
          displayName:this.user.s_name,
          avatar:this.user.s_logo,
        }
      this.initWebSocket();

    },
    menuList() {
      
      for (let i = 0; i < this.$router.options.routes.length; i++) {
        if (!this.$router.options.routes[i].hidden) {
          this.menu = this.$router.options.routes[i].children;
        }
      }
      this.getPath();
    },
    handleOk() {
      if (this.form.old_password == "") {
        this.$message.error("请输入原密码");
      } else if (this.form.password == "") {
        this.$message.error("请输入新密码");
      } else if (this.form.password_confirm == "") {
        this.$message.error("确认新密码");
      } else {
        this.subLoading = true;
        updatePassword(qs.stringify(this.form)).then(res => {
          this.subLoading = false;
          if (res.data.code == "0") {
            this.alert = false;
            this.$message.success("修改成功");
          } else if (res.data.code == "1") {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    menuClick(item, index) {
      if (index == "none") {
        this.$router.push(item.path);
      } else {
        let path = item.children[index].path;
        this.$router.push(path);
      }
      // setTimeout(() => {
      //   this.getPath();
      // }, 50);
    },
    getPath() {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].children) {
          for (let j = 0; j < this.menu[i].children.length; j++) {
            if (this.$route.path == this.menu[i].path) {
              this.path = this.menu[i].name;
            } else if (this.$route.path == this.menu[i].children[j].path) {
              this.path =
                this.menu[i].name + " / " + this.menu[i].children[j].name;
            }
          }
        } else {
          if (this.$route.path == this.menu[i].path) {
            this.path = this.menu[i].name;
          }
        }
      }
    },
    onClick({ key }) {
      if (`${key}` == "1") {
        this.alert = true;
      } else if (`${key}` == "2") {
        this.$confirm({
          title: "提示",
          content: "您是否要退出登录?",
          okText: "确定",
          okType: "primary",
          cancelText: "取消",
          onOk: () => {
            sessionStorage.clear();
            this.$router.push("/login");
          },
          onCancel() {
            console.log("Cancel");
          }
        });
      }
    },
    getStockList() {
      getStockList().then(res => {
        if (res.data.code == 0) {
          this.stockList = res.data.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    initWebSocket() {
      // const wsuri = "wss://xcxautopartcq.shop.lyxiu.cc/websocket";
      // const wsuri = "ws://47.104.212.249:7272";
      const wsuri = "wss://api2.jcwh.xyz/websocket";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      var login_data = '{"type":"login","uid":"'+this.userInfo.id+'"}';
      this.websock.send(login_data);
    },
    websocketonerror() {
      this.initWebSocket();
    },
    websocketonmessage(e) {
      const message = JSON.parse(e.data);
      if (message.type == "say") {
        this.getLastMessage(message.m_id);
        this.$refs["new_message"].play();
      }else if(message.type == "baojia") {
        this.$notification.open({
            message: '询价订单提醒',
            description: '你有新的询价订单，请及时报价',
            duration:'20',
            icon: <a-icon type="smile" style="color: #108ee9" />
        });
        this.$refs["audio"].play();
        this.$store.commit("add");
      }else if(message.type == "order") {
        this.$notification.open({
            message: '报价订单提醒',
            description: '你有新的报价订单，请及时发货',
            duration:'20',
            icon: <a-icon type="smile" style="color: #108ee9" />
        });
        this.$refs["audio"].play();
        this.$store.commit("addOrderCount");
	  }else if(message.type == "transfer") {
	    this.$notification.open({
	        message: '转账提醒',
	        description: '你有新的报价订单，用户已转账，请及时发货',
	        duration:'20',
	        icon: <a-icon type="smile" style="color: #108ee9" />
	    });
	    this.$refs["audio"].play();
	    this.$store.commit("addTransferCount");
      }
    },
    websocketsend(data) {
      this.websock.send(data);
    },
    websocketclose(e) {
      // clearInterval(this.setTime);
      setTimeout(function() {
        this.initWebSocket();
      },1000);
      
    },
    //发送消息
    handleSend(message, next, file) {
      console.log(message);
      console.log(message.type);
      if (message.type == "image") {
        let formData = new FormData(); // 项目整体格式上传采用form数据上传

        formData.append("file", file);
        uploads(formData).then(res => {
          message.content = res.data.data;
          message_add(qs.stringify(message)).then(res => {
            var data =
              '{"type":"say","uid":"179","content":"' +
              message.content +
              '","m_id":"' +
              res.data.data +
              '"}';
            this.websocketsend(data);
            next();
          });
        });
      } else {
        message_add(qs.stringify(message)).then(res => {
          var data =
            '{"type":"say","uid":"179","content":"' +
            message.content +
            '","m_id":"' +
            res.data.data +
            '"}';
          this.websocketsend(data);
          next();
        });
      }
    },
    //获取消息
    handlePullMessages(contact, next,instance) {
     
      let message  = instance.currentMessages;
      let length = message.length
      let last_id = 0;
      if(length>0){
        last_id = message[0].id;
      }
      
      message_list(qs.stringify({ uid: contact.id,last_id:last_id })).then(res => {
        next(res.data.data.list, res.data.data.isend);
      });
    },
    //接收消息
    handleMessage() {
      // const data = {
      //   id: "20",
      //   status: "succeed",
      //   type: "text",
      //   sendTime: 1650457477000,
      //   content: "知道了",
      //   toContactId: 1,
      //   fromUser: {
      //     //如果 id == this.user.id消息会显示在右侧，否则在左侧
      //     id: 2,
      //     displayName: "丽安娜",
      //     avatar: ""
      //   }
      // };
      // const { IMUI } = this.$refs;
      // IMUI.appendMessage(data);
    },
    appendContact() {
      let contact = this.$store.state.contact;
      if(contact.id) {
        const { IMUI } = this.$refs;
        IMUI.appendContact(contact);
        IMUI.changeContact(contact.id)
      }
      
    },
    changeContact(contact) {
      contact.unread = 0;
      this.updateRead(contact.id);
    },
    closeChat() {
      this.$store.commit("chatOpen");
    },
    getUserList() {
      const { IMUI } = this.$refs;
      message_peopleList().then(res => {
        this.userList = res.data.data;

        IMUI.initContacts(res.data.data);
        IMUI.initEditorTools([{name:'uploadImage'}]);
      });
    },
    updateRead(contact_id) {
      updateRead(qs.stringify({ uid: contact_id })).then(res => {
      });
    },
    messageClick(event,key,Message) {
    
      console.info(Message);
      if(Message.type == 'image') {
        this.thisImage = Message.content
        this.imageModel = true;
      }

  },
  imageColse() {
    this.imageModel = false;
  },
    getLastMessage(m_id) {
      const { IMUI } = this.$refs;
      getLastMessage(qs.stringify({ m_id: m_id })).then(res => {
        
        let fromUser = res.data.data.fromUser;
        let userList = this.userList;
        let isTrue = false;
        userList.map(item=>{
            if(item.id == fromUser.id) {
              isTrue = true;
            }
        });
        //如果列表用户不存在就重新获取
        if(!isTrue) {
          this.getUserList();
        }

        

        IMUI.appendMessage(res.data.data);
        IMUI.messageViewToBottom();
        if(!this.chatHide) {
          this.messageText = "有新的消息";
        }
        let current_contact = IMUI.getCurrentContact();
        if(current_contact.id) {
          this.updateRead(current_contact.id);
        }
      });
    },
  },
  
  mounted() {
    this.menuList();
    this.getStockList();
    this.getUserList();
    this.storeInfo();
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    

    // const { IMUI } = this.$refs;
    // IMUI.initEditorTools([{name:'uploadImage'}]);

    // appendContact
    // this.$notification.open({
    //         message: '询价订单提醒',
    //         description: '你有新的询价订单，请及时报价',
    //         icon: <a-icon type="sound" style="color: green" />
    //     });
  }
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
}
.header_phone {
  padding: 0 5px;
  color: #666;
}
.header_icon {
  color: #666;
}
.ant-layout-header {
  border-bottom: 1px solid #ddd;
}
</style>
